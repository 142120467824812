<template lang="pug">
  el-card.card
    el-page-header(@back="$router.push('/')" content="TAP" title="Назад")
    .content

      el-header
        strong {{ type === 'order' ? 'ЗАКАЗ' : 'АБОНЕМЕНТ' }}

      template(v-if="type === 'order' && data && data.coworking && data.user && data.place")
        span Коворкинг:&nbsp;
          strong {{ data.coworking.name }} - {{data.coworking.address}}
        br
        span Пользователь:&nbsp;
          strong {{ data.user.name }}
        br
        span Место:&nbsp;
          strong {{ data.place.name }}
        br
        span Цена в час:&nbsp;
          strong {{ data.place.price }}
            span(v-if="data.place.stopPrice") (но не более {{data.place.stopPrice}})
        br
        span Планируемое начало брони:&nbsp;
          strong {{ date(data.planned_date) }}
        br
        span Планируемое завершение брони:&nbsp;
          strong {{ date(data.planned_end_date) }}
        br
        template(v-if="data.status === 'started'")
          span Бронь была начата:&nbsp;
            strong {{date(data.start_date)}}
          br
        template(v-if="['completed', 'declined', 'canceled'].includes(data.status)")
          span Стоимость визита:&nbsp;
            strong {{ data.price }}
          br
        span Статус:&nbsp;
          strong {{data.status}}
        br

      template(v-if="type === 'order'")
        el-button(v-on:click.once="start" v-if="data.status === 'created' || data.status === 'approved'") Начать сессию
        el-button(v-on:click.once="stop" v-if="data.status === 'started'") Завершить сессию

      template(v-if="type === 'subscription' && data && data.coworking && data.user && data.place")
        span Коворкинг:&nbsp;
          strong {{ data.coworking.name }} - {{data.coworking.address}}
        br
        span Пользователь:&nbsp;
          strong {{ data.user.name }}
        br
        span Место:&nbsp;
          strong {{ data.place.name }}
        br

</template>

<script>
import Api from "@/api";

export default {
  data: () => ({
    uuid: '',
    data: {},
    type: 'order',
  }),
  async mounted() {
    this.uuid = this.$route.params.uuid;
    const data = await Api.get(this.uuid);

    this.type = data.type;
    this.data = data.data;
  },
  methods: {
    date(d) {
      const date = new Date(d);
      return date.toLocaleString('ru');
    },
    async start() {
      this.data = await Api.start(this.uuid);

      alert('Сессия успешно начата');
      await this.$router.push('/');
    },
    async stop() {
      Api
          .stop(this.uuid)
          .catch(console.error);

      alert('Сессия успешно завершена');
      await this.$router.push('/');
    },
  }
}
</script>

<style>
  .content {
    margin-top: 20px;
    text-align: left;
    display: block;
  }
</style>
