import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import router from "@/routes";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://d191f3c46bba4d76980410811d693b5c@o1108917.ingest.sentry.io/6740580",
  release: "tap-admin@1.0.0",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 2.0,
});

Vue.use(VueRouter)
Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
