<template lang="pug">
  #app
    el-card(v-if="!auth").card
      h2 TAP admin panel
      el-input(
        type="email"
        v-model="email"
        placeholder="Введите email"
      )

      el-input.margin-top(

        type="password"
        v-model="password"
        placeholder="Введите пароль"
      )
      span(v-if="incorrectPassword").error Неверный пароль
      el-button.card-btn(type="primary" v-on:click="enter") Войти
    router-view(v-else)
</template>

<script>
import OrderPage from './components/OrderScan.vue'
import axios from "./utils/axios";

export default {
  name: 'App',
  components: {
    OrderPage
  },
  data: () => ({
    auth: false,
    password: '',
    email: '',
    incorrectPassword: false,
  }),
  mounted() {
    this.password = localStorage.getItem('password') || '';
    this.email = localStorage.getItem('email') || '';
    this.enter({first: true});
  },
  methods: {
    async enter({ first = false } ) {
      const {data} = await axios
        .post('/local-admin/auth', {
          password: this.password,
          email: this.email,
        });

      this.auth = data.auth === true;
      if (this.auth) {
        localStorage.setItem('password', this.password);
        localStorage.setItem('email', this.email);
      } else if (!first) {
        this.incorrectPassword = true;
      }
      this.password = '';
    }
  }
}
</script>

<style>

.margin-top{
  margin-top: 20px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.card {
  width: 500px;
  max-width: calc(100% - 60px);
  padding: 20px;
  margin: 0 auto;
}
.card-btn {
  margin-top: 20px !important;
}
.error {
  color: red;
  display: block;
  margin-top: 20px;
}

body {
  margin: 0;
}
</style>
