<template lang="pug">
  el-card.card
    el-page-header(@back="exit" content="TAP admin panel" title="Выход")
    span.hello Привет! Скоро здесь будет большая крутая панель управления 🥰

    el-button(v-if="!enableVideo" type="primary" v-on:click="scanQR") Сканировать QR код клиента

    video(v-show="enableVideo")


</template>

<script>

import QrScanner from 'qr-scanner';
import Api from "@/api";

QrScanner.WORKER_PATH = '/qr-scanner-worker.min.js';

const validateUUID = uuid => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(uuid);

// 0e6f57df-0b79-4723-b2d9-1ddffe72d09e

export default {
  name: 'OrderScan',
  data: () => ({
    qrScanner: null,
    enableVideo: false,
    qrRecognized: false,
  }),
  methods: {
    exit() {
      localStorage.removeItem('password');
      window.location.reload();
    },
    scanQR() {
      this.enableVideo = true;
      this.qrScanner = new QrScanner(
          document.querySelector('video')
          , async uuid => {

            if (!validateUUID(uuid) || this.qrRecognized) return;

            const { status } = await Api.get(uuid);

            if (status === 404) return;

            this.qrRecognized = true;

            this.qrScanner.stop();

            await this.$router.push(`/${uuid}`);
          },
      );
      this.qrScanner.start();
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.hello {
  text-align: left;
  margin-top: 40px;
  display: block;
}

.row-block {
  margin-left: -20px !important;
  margin-top: 20px;
}

video {
  width: 100%;
}
</style>
