import VueRouter from 'vue-router';
import OrderPage from "@/components/OrderScan";
import OrderEdit from "@/components/OrderEdit";

const routes = [
    { path: '/', component: OrderPage },
    { path: '/:uuid', component: OrderEdit }
]

const router = new VueRouter({
    routes,
    mode: 'history',
})

export default router;
