import axios from "@/utils/axios";

export default class Api {

    /**
     * @private
     */
    static get authObject() {
        return {
            password: localStorage.getItem('password'),
            email: localStorage.getItem('email'),
        }
    }

    static get(uuid) {
        return axios
            .post(`/local-admin/order/${uuid}`, Api.authObject)
            .then(r => r.data);
    }

    static async start(uuid) {
        return axios
            .post(`/local-admin/order/${uuid}/start`, Api.authObject)
            .then(r => r.data.data);
    }

    static async stop(uuid) {
        return axios
            .post(`/local-admin/order/${uuid}/stop`, Api.authObject)
            .then(r => r.data.data);
    }

}
